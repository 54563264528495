import axios from 'axios';
import React, { Component } from "react";

export default class SignUp extends Component {

    createUser = event => {

        //example post request + console log and error catcher
        axios.post('http://localhost:8000/api/user/create/', 
        {
            "email": document.getElementById('email').value,
            "password": document.getElementById('pword').value,
            "name": document.getElementById('first').value + ' ' + document.getElementById('last').value,
        },)
            .then(response => {
                    window.location = "/sign-in";
            })
            .catch(err => {
                console.log(err);
            }
        );
    };

    render() {
        return (
            <form>
                <h3>Sign Up</h3>

                <div className="form-group">
                    <label>First name</label>
                    <input type="text" id='first' className="form-control" placeholder="First name" />
                </div>

                <div className="form-group">
                    <label>Last name</label>
                    <input type="text" id='last' className="form-control" placeholder="Last name" />
                </div>

                <div className="form-group">
                    <label>Email address</label>
                    <input type="email" id='email' className="form-control" placeholder="Enter email" />
                </div>

                <div className="form-group">
                    <label>Password</label>
                    <input type="password" id='pword' className="form-control" placeholder="Enter password" />
                </div>

                <button type="button" className="wide_button" onClick={this.createUser}>Sign Up</button>

                <p className="subscript text-right">
                    Already registered <a href="sign-in">sign in?</a>
                </p>
            </form>
        );
    }
}